<script setup lang="ts">
import Papa from "papaparse";
import type { Database } from "~/types/database.js";

const goToTopVisible = ref(false);

const { data } = await useAsyncQuery<{
  database: {
    last_updated: string;
    csv?: {
      url: string; };
    xls?: { url: string };
  };
}>(
  gql`
    query {
      database(uid: "database", lang: "en-gb") {
        csv {
          ... on _FileLink {
            url
          }
        }
        xls {
          ... on _FileLink {
            url
          }
        }
        last_updated
      }
    }
  `,
);

if (data.value) {
  const parse = async (url: string) => {
    const rawData = await $fetch<string>(url);

    // Disable eslint rule here because Papaparse is crap (bring on v6)
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const { data } = await Papa.parse<Database["data"][0]>(
      rawData,
      {
        header: true, // This uses the first line as object keys
        transform: (value) => value.trim(), // This trims whitespace from around values
        transformHeader: (value) => value.trim(),
        skipEmptyLines: true,
      },
    );

    return data;
  };

  useDatabase().value = {
    lastUpdated: data.value.database.last_updated,
    url: data.value.database.csv?.url ?? "",
    download: data.value.database.xls?.url ?? "",
    data: data.value.database.csv ? await parse(data.value.database.csv.url) : [],
  };
}

useHead({
  titleTemplate: (title?: string) => (title ? `${title} | CALF` : "CALF"),
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const watchScroll = () => {
  goToTopVisible.value = window.scrollY > 64;
};

onMounted(() => {
  window.addEventListener("scroll", watchScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", watchScroll);
});
</script>

<template>
  <CookieBar />
  <AppHeader />
  <main>
    <NuxtPage />
  </main>
  <AppButton
    v-if="goToTopVisible"
    class="go-to-top"
    @click="scrollToTop"
  >
    <Icon
      name="icons:arrow-left"
      :size="48"
    />
  </AppButton>
  <AppFooter />
</template>

<style lang="scss">
body {
  margin: 0;

  &.z-index-reset {
    overflow-y: hidden;

    header,
    section {
      z-index: initial; // This is a hack for AppModal working with all our z-index usage
    }
  }
}

.go-to-top {
  position: fixed;
  z-index: 2000;
  bottom: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  padding: 8px !important;
  max-width: 64px;
  transform: rotate(90deg);
}

#__nuxt {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $white1;
  color: $blue1;
  font-family: $font-body;
  font-size: rem-calc(16px);
  line-height: 1.4;

  main {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: stretch;
    overflow-x: hidden;

    article {
      width: 100%;
    }
  }
}

// Fade in/out transitions for pages
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

a {
  color: $blue2;
}

h1 {
  font-family: $font-primary;
  font-size: rem-calc(48.8px);
  font-weight: 600;
  margin: 24px 0;

  @include tablet {
    font-size: rem-calc(28.8px);
    margin: 8px 0;
  }
}

h2 {
  font-family: $font-primary;
  font-size: rem-calc(38.1px);
  font-weight: 600;
  margin: 24px 0;

  @include tablet {
    font-size: rem-calc(25.6px);
    margin: 8px 0;
  }
}

h3 {
  font-family: $font-body;
  font-size: rem-calc(25px);
  font-weight: 500;

  @include tablet {
    font-size: rem-calc(20.3px);
    margin: 8px 0;
  }
}

h4 {
  font-family: $font-body;
  font-size: rem-calc(18px);
  font-weight: 700;
  margin: 16px 0;

  @include tablet {
    margin: 8px 0;
  }
}

input,
select,
textarea {
  font-family: $font-secondary;
  font-size: rem-calc(18px);
  font-weight: 700;
  color: $blue1;
  background-color: $blue4;
  border: 1px solid $blue2;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

select {
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, $grey1 50%),
    linear-gradient(135deg, $grey1 50%, transparent 50%),
    linear-gradient(to right, $grey4, $grey4);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select:focus {
  background-image: linear-gradient(45deg, $blue2 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, $blue2 50%),
    linear-gradient(to right, $grey4, $grey4);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: $blue2;
  outline: 0;
}

label > input[type="checkbox"] {
  display: none;
}

label > input[type="checkbox"] + *::before {
  content: "";
  min-width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid $blue2;
  background-color: $white1;
  font-family: $font-secondary;
}

label > input[type="checkbox"]:checked + *::before {
  content: "✓";
  text-align: center;
  background: $blue2;
}

.topics {
  span {
    display: inline-block;
    background-color: $blue4;
    border: 1px solid $blue2;
    border-radius: 3px;
    padding: 2px 4px;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  a,
  span {
    font-size: rem-calc(14px) !important;
    font-family: $font-body !important;
    color: $blue2;
    text-decoration: none;
    font-weight: 500 !important;
    vertical-align: initial !important;
  }
}

ol {
  li::marker {
    content: counter(list-item) ") ";
  }
}

.species {
  span {
    display: inline-block;
    border-radius: 2px;
    padding: 1px 2px 2px;
    font-size: rem-calc(14px);
    margin-right: 4px;
    white-space: nowrap;
    line-height: rem-calc(16px);

    &.all-animals {
      background-color: $grey3;
    }

    &.farmed-animals {
      background-color: $lilac1;
    }

    &.beef-cows {
      background-color: $orange1;
    }

    &.dairy-cows {
      background-color: $yellow1;
    }

    &.broiler-chickens {
      background-color: $green1;
    }

    &.pigs {
      background-color: $pink1;
    }

    &.fish {
      background-color: $purple1;
    }

    &.egg-laying-hens {
      background-color: $turquoise1;
    }

    &.sheep-and-goats {
      background-color: $lilac1;
    }

    &.calves {
      background-color: $red1;
    }

    &.alternative-proteins {
      background-color: $lime1;
    }
  }
}

.page-header {
  & + div > section > div {
    border-top: 0 none !important;
  }

  &.isLegislative + div > section {
    padding-top: 0 !important;
  }
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease-in-out;
}
</style>
